module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[],"modalProps":{"style":{"overlay":{"backgroundColor":"rgba(0, 0, 0, 0.5)"},"content":{"position":"absolute","border":"none","background":"none","padding":0,"top":0,"bottom":0,"right":0,"left":0,"overflow":"auto","WebkitOverflowScrolling":"touch"}}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"The Shows Must Go Online","short_name":"The Shows Must Go Online","start_url":"/","background_color":"#fff","theme_color":"#553D67","display":"standalone","icon":"src/images/favicon-32x32.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9ea87a46e40db8db0fc1c7b5770d6152"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-42166600-22","head":true,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
