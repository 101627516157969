// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-affiliate-disclaimer-jsx": () => import("./../../../src/pages/affiliate-disclaimer.jsx" /* webpackChunkName: "component---src-pages-affiliate-disclaimer-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-submit-jsx": () => import("./../../../src/pages/submit.jsx" /* webpackChunkName: "component---src-pages-submit-jsx" */),
  "component---src-pages-subscribe-jsx": () => import("./../../../src/pages/subscribe.jsx" /* webpackChunkName: "component---src-pages-subscribe-jsx" */),
  "component---src-templates-amazon-jsx": () => import("./../../../src/templates/amazon.jsx" /* webpackChunkName: "component---src-templates-amazon-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blog-post.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-books-jsx": () => import("./../../../src/templates/books.jsx" /* webpackChunkName: "component---src-templates-books-jsx" */),
  "component---src-templates-paginated-film-page-jsx": () => import("./../../../src/templates/paginated-film-page.jsx" /* webpackChunkName: "component---src-templates-paginated-film-page-jsx" */),
  "component---src-templates-paginated-streaming-page-calendar-jsx": () => import("./../../../src/templates/paginated-streaming-page-calendar.jsx" /* webpackChunkName: "component---src-templates-paginated-streaming-page-calendar-jsx" */),
  "component---src-templates-paginated-streaming-page-jsx": () => import("./../../../src/templates/paginated-streaming-page.jsx" /* webpackChunkName: "component---src-templates-paginated-streaming-page-jsx" */),
  "component---src-templates-single-item-jsx": () => import("./../../../src/templates/single-item.jsx" /* webpackChunkName: "component---src-templates-single-item-jsx" */),
  "component---src-templates-single-page-jsx": () => import("./../../../src/templates/single-page.jsx" /* webpackChunkName: "component---src-templates-single-page-jsx" */)
}

